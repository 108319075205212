import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  images = ["RG Sure", "Sargical", "Heal Cartilage", "Celetoletif", "Arthocal D", "Suredite"].map((n) => `assets/sliderimages/${n}.webp`);

  constructor() { }

  ngOnInit(): void {
  }

}
