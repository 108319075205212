<ul class="navbar-nav" style="flex-direction: row;">

    <li class="nav-item">
        <a class="nav-link" aria-label="twitter page" href="#"><i class="fa-cog fa fa-twitter fa-lg"></i></a>
    </li>

    <li class="nav-item">
      <a class="nav-link" aria-label="linkedin page" href="#"><i class="fa-cog fa fa-linkedin fa-lg"></i></a>
    </li>

    <li class="nav-item">
      <a class="nav-link" aria-label="instagram page" href="#"><i class="fa-cog fa fa-instagram fa-lg"></i></a>
    </li>

    <li class="nav-item">
      <a class="nav-link" aria-label="facebook page" href="https://www.facebook.com/pages/category/Local-Business/luminous-pharma-Pvtltd-662788317190470/"><i class="fa-cog fa fa-facebook fa-lg"></i></a>
    </li>
  </ul>