import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-product-slider',
  templateUrl: './product-slider.component.html',
  styleUrls: ['./product-slider.component.css']
})
export class ProductSliderComponent implements OnInit {

  images = ['Pharmaceutical','Nutraceutical','Surgical','Cosmetics'].map((n) => `../../../assets/productslider/${n}.webp`);
  
  msg = ['Pharmaceutical',
         'Nutraceutical',
         'Surgical',
         'Cosmetics'];

  constructor() { }

  customOptions: OwlOptions = {
    margin: 30,
    loop: true,
    autoplay: true,
    lazyLoad:true,
    autoplayTimeout: 5000,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: true,
    // dots: false,
    navSpeed: 700,
    nav: true,
    dots:false,
    navText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>', '<i class="fa fa-chevron-right" aria-hidden="true"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 4
      },
      740: {
        items: 4
      },
      940: {
        items: 4
      }
    },
    
  }


  ngOnInit(): void {
  }

}
