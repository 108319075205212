import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { TheCompanyComponent } from './components/the-company/the-company.component';

const routes: Routes = [
  { path: 'home', component:HomeComponent},
  { path: '', component:HomeComponent},
  { path: 'the-company', component:TheCompanyComponent},
  { path: 'our-team', loadChildren: () => import('./components/our-team/our-team.module').then(m => m.OurTeamModule) },
  { path: 'gallery', loadChildren: () => import('./components/gallery/gallery.module').then(m => m.GalleryModule) },
  { path: 'product', loadChildren: () => import('./components/product/product.module').then(m => m.ProductModule) },
  { path: 'Cosmetics', loadChildren: () => import('./components/differentproducts/cosmetics/cosmetics.module').then(m => m.CosmeticsModule) },
  { path: 'Nutraceutical', loadChildren: () => import('./components/differentproducts/nutraceuticals/nutraceuticals.module').then(m => m.NutraceuticalsModule) },
  { path: 'Pharmaceutical', loadChildren: () => import('./components/differentproducts/pharmaceuticals/pharmaceuticals.module').then(m => m.PharmaceuticalsModule) },
  { path: 'Surgical', loadChildren: () => import('./components/differentproducts/surgicals/surgicals.module').then(m => m.SurgicalsModule) },
  { path: 'contact', loadChildren: () => import('./components/contact/contact.module').then(m => m.ContactModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
