import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-the-company',
  templateUrl: './the-company.component.html',
  styleUrls: ['./the-company.component.css']
})
export class TheCompanyComponent implements OnInit {

  constructor(public router:Router) { }


  ngOnInit(): void {
  }

}
