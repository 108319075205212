import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './components/footer/footer.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { SocialmediaComponent } from './components/socialmedia/socialmedia.component';
import { CopyrightComponent } from './components/copyright/copyright.component';
import { MenuComponent } from './components/menu/menu.component';
import { MenuItemsComponent } from './components/menu-items/menu-items.component';
import { HomeComponent } from './components/home/home.component';
import { ProductSliderComponent } from './components/product-slider/product-slider.component';
import { TheCompanyComponent } from './components/the-company/the-company.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    CarouselComponent,
    SocialmediaComponent,
    CopyrightComponent,
    MenuComponent,
    MenuItemsComponent,
    HomeComponent,
    ProductSliderComponent,
    TheCompanyComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CarouselModule,
    AppRoutingModule,
    NgbModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [{provide:LocationStrategy, useClass:HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
