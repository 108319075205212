<app-carousel></app-carousel>

<div class="row" style="margin-left: 8px; margin-top: 5px;">

  <div class="col-md-4">
      
    <div class="col" style="background-color: black; margin-top: 5px;">
      <h2 style="color:white">Executive</h2>
   </div>
      <div class="col" *ngFor="let image of images">
              <img [src]="image" style="width: 100%; height: auto;" alt="owner">
      </div> 
      <div class="col directortitle">
          <h4>Mr. Ramanand Das</h4>
          <h5>Managing Director / Chairman</h5>
      </div>
      <div class="col directortitle">
        <h4>Remember Us</h4>
        <h6>For any kind of Nutraceutical, Pharmaceutical, Surgical and Cosmetic products.</h6>
        <h6>Be Healthy and Happy</h6>
    </div>
  </div>
  <div class="col-md-8">
    <div class="col">
      <h2>Luminous Pharma Pvt. Ltd.</h2>
   </div>
    <app-the-company></app-the-company>
  </div>
  
  </div>

<section id="services" style="margin-top: 5px;">
    <div>
      <h3 class="header-title"><span>Our </span> Products</h3>
    </div>
    <app-product-slider></app-product-slider>
</section>