<div class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <h3>Menu</h3>
          <!-- <app-menu-items></app-menu-items> -->
          <ul ngbNav #nav="ngbNav">
            <a ngbNavItem="home" ngbNavLink routerLink="home" aria-label="home"><i class="fa fa-home fa-lg"></i></a>
            <a ngbNavItem="About" ngbNavLink routerLink="the-company">The Company</a>
            <a ngbNavItem="Product" ngbNavLink routerLink="product">Product</a>
            <a ngbNavItem="Our Team" ngbNavLink routerLink="our-team">Our Team</a>
            <a ngbNavItem="Gallery" ngbDropdown ngbNavLink routerLink="gallery">Gallery</a>
            <a ngbNavItem="Contact" ngbNavLink routerLink="contact">Contact</a>
          </ul>
        </div>
        <div class="col">
          <h3>Follow us</h3>
          <app-socialmedia></app-socialmedia>
        </div>
        <div class="col">
          <h3>Products</h3>
          <a routerLink="/Pharmaceutical">Pharmaceutical</a><br>
          <a routerLink="/Nutraceutical">Nutraceutical</a><br>
          <a routerLink="/Surgical">Surgical</a><br>
          <a routerLink="/Cosmetics">Cosmetics</a><br>
        </div>
        <div class="col">
          <h3>Location</h3>
            <div class="gmap_canvas">
              <iframe width="300" height="300" id="gmap_canvas" title="My Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3064.453730844236!2d85.33921234259704!3d27.6621388870631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjfCsDM5JzQzLjciTiA4NcKwMjAnMjguMCJF!5e1!3m2!1sen!2snp!4v1622787965799!5m2!1sen!2snp" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
          </div>
        </div>
      </div>
    </div>
    </div>