<div class="continer-fluid">
    <div class="title-container" *ngIf = "router.url !== '/'">
      <div>About Us</div>
    </div>
  </div>
  
  <div class="container">
  <p>
  Established in June 2008, Luminous Pharma Pvt. Ltd. is one of the leading company of Nutraceuticals product in Nepal. 
  It has always tried to provide quality products to its customers. 
  It has been actively participating in the pharmaceutical business with import and export of variety of pharmaceutical and nutraceutical products. 
  It directly import its products from abroad and conduct marketing with highly motivated marketing professionals through multiple distributers/stockists and retailers throughout the country. 
  </p>
  <p>
  The main focus area of Luminous Pharma is nutraceutical products like Arthocal-D, Cellutole, Lumidite, Lumipro, Moms Dite, Heal Cartilage, and so on. 
  The company aims to provide high quality of nutrients rich protein supplements at minimal cost maintaining all business ethics. Luminous has been serving the health care area of the country with strong motivation of boosting the living standard of people by reducing nutrients related problems among them. 
  Luminous pharma as a group of company have variety of health services directly through their patient.
  Luminous Pharma has been trying to abolish the unemployment problem of the country by providing job opportunities to eligible candidates since its establishment and is well appreciated by the national companies as well.
  </p>
  
  <h3>Aim</h3>
  <h6> Aim of Luminous Pharma is "to supply special care for every generation".</h6>
  
  <h3>Vision</h3>
  <h6>We want to remain a supreme player in pharmaceutical and nutraceutical products in the Country while also extending trustworthy and cost effective services to other branch of pharma products.</h6>
  
  </div>