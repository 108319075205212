<section id="services" class="services bg-white section-padding">
    <div class="container">
  
      <owl-carousel-o [options]="customOptions">
  
          <ng-template carouselSlide *ngFor ="let image of images; let i = index">
            <div class="card services-desc pointer-link" routerLink="//{{msg[i]}}">
              <img [src]="image" class="card-img-top" alt="Service Photo">
              <div class="card-body">
              <h5 class="card-title" style="font-size:13px; text-align: center;"><strong [innerText]="msg[i]"></strong></h5>
            </div>
            </div>
          </ng-template>
  
      </owl-carousel-o>
    </div>
  </section>
