<!-- This component is for menu bar background. It has used menu-items component for
displaying menu list; socialmedia component for displaying social media icons; 
and logout component for displaying Admin photo and logout -->

<nav class="navbar navbar-expand-sm navbar-custom">

    <div class="col-md-2" style="padding-left: 30px;">
        <a href="https://luminouspharma.com/">
            <img src="/assets/logo/logo.webp" alt="logo">
        </a>
    </div>
    <div class="col-md-7" style="padding-left: 100px;">
        <app-menu-items></app-menu-items>
    </div>
    <div class="col-md-3" style="padding-left: 50px;">
        <form action="">
          <input type="text" placeholder="Search Products" name="search">
          <button class="button" aria-label="Search Products" type="submit"><i class="fa fa-search"></i></button>
        </form>
      </div>
  </nav>
